import React from 'react';
import { Box, Grid } from '@mui/material';
import { ButtonUI, SelectUI } from '../../../../components/common';

export const BlockAdd = ({
  agregar,
  selects,
  handleChangeSelect,
  handleAgregarBlock,
}) => {
  return (
    <Box component="form" onSubmit={handleAgregarBlock}>
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <SelectUI
            data={agregar.bl_tipo}
            options={selects.bloques}
            handleChangeSelect={handleChangeSelect}
          />
        </Grid>
        <Grid item xs={12}>
          <ButtonUI
            type="submit"
            label="Agregar"
            variant="outlined"
            color="primary"
            size="small"
            disabled={agregar.bl_tipo.value === 0}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
