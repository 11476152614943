import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { BlockAdd } from './BlockAdd';
import { BlockList } from './BlockList';
import { InfoText } from '../InfoText';
import { ModalUI } from '../../../../components/common';

export const BlockSettings = ({
  bloques,
  agregar,
  selects,
  handleChangeSelect,
  handleOrdenarBlocks,
  handleChangeMenuTab,
  handleBorrarBlock,
  handleAgregarBlock,
  handleDisableEnableBlock,
}) => {
  const [index, setModalState] = useState(null);
  const toggleModalBorrar = (index) => setModalState(index);

  return (
    <>
      <ModalUI
        open={index !== null}
        id="borrar"
        title="Borrar sección"
        modalHandler={() => toggleModalBorrar(null)}
        aditional_param="borrar"
        handleSubmit={(e) => {
          handleBorrarBlock(e, index);
          toggleModalBorrar(null);
        }}
        button_label="Borrar"
      >
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Typography>
              ¿Querés borrar la sección? Esta acción no se podrá deshacer.
            </Typography>
          </Grid>
        </Grid>
      </ModalUI>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <InfoText
            first
            title="Secciones"
            text="Las secciones son los diferentes componentes de tu sitio web"
          />
          <BlockList
            bloques={bloques}
            toggleModalBorrar={toggleModalBorrar}
            handleOrdenarBlocks={handleOrdenarBlocks}
            handleChangeMenuTab={handleChangeMenuTab}
            handleDisableEnableBlock={handleDisableEnableBlock}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoText
            title="Agregar nueva sección"
            text="Puedes agregar nuevas secciones a tu tienda"
          />
          <BlockAdd
            agregar={agregar}
            selects={selects}
            handleChangeSelect={handleChangeSelect}
            handleAgregarBlock={handleAgregarBlock}
          />
        </Grid>
      </Grid>
    </>
  );
};
