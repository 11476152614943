export const getCeresJson = () => {
  return {
    id: 1,
    css: '',
    body: {
      background: '#ffffff',
    },
    fonts: {
      text: 30,
      title: 30,
    },
    other: {
      menu_type: 0,
      border_radius: 6,
      menu_label_products: 'Productos',
      menu_label_wholesaler: 'Mayoristas',
    },
    popup: {
      html: '',
      link: '',
      text: '',
      type: 0,
      image: '',
      title: '',
      active: 0,
      button: '',
      visibility: 0,
      visibility_place: 0,
    },
    message: {
      link: '',
      text: '3 CUOTAS SIN INTERÉS - ENVÍOS GRATIS',
    },
    primary: {
      text: '#000000',
      background: '#000000',
      contrastText: '#ffffff',
    },
    checkout: {
      comments: 0,
      step_review: 0,
      short_billing_form: 0,
    },
    secondary: {
      text: '#1c1c1c',
      background: '#1c1c1c',
      contrastText: '#ffffff',
    },
    products_feed: {
      order: 3,
      filter: 0,
      masonry: 0,
      product_subtext: 'HASTA 12 CUOTAS',
      product_subtext_type: 0,
      product_subtext_cuotas: 3,
    },
    products_detail: {
      photo_zoom: 0,
      photo_display: 1,
    },
  };
};
